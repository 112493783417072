import { template as template_42bc8be0bca14e04a9727cb9fdd81bf0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_42bc8be0bca14e04a9727cb9fdd81bf0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
